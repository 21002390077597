const formMessages = {
  noForename: 'Der Vorname ist eine Pflichtangabe.',
  noSurname: 'Der Nachname ist eine Pflichtangabe.',
  noMail: 'Die E-Mail ist eine Pflichtangabe.',
  noDate: 'Das Datum ist eine Pflichtangabe.',
  invalidMail: 'Ungültige E-Mail-Adresse.',
  tooManyParticipants:
    'Aktuell ist eine Maximalanzahl von 30 Teilnehmern möglich.',
  uncheckedDataPrivacy: 'Die Datenschutzerklärung muss akzeptiert werden.',
  success: 'Die Anfrage wurde erfolgreich versendet.',
  fail:
    'Die Anfrage konnte leider nicht versendet werden. Bitte versuchen Sie es später nochmal.'
};

export default formMessages;
