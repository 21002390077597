const formFields = {
  personal: [
    {
      type: 'text',
      name: 'company',
      label: 'Firma'
    },
    {
      type: 'text',
      name: 'forename',
      label: 'Vorname',
      isRequired: true,
      hasValidation: true
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Nachname',
      isRequired: true,
      hasValidation: true
    },
    {
      type: 'email',
      name: 'email',
      label: 'E-Mail',
      isRequired: true,
      hasValidation: true
    },
    {
      type: 'tel',
      name: 'phone',
      label: 'Telefon'
    }
  ],
  event: [
    {
      type: 'text',
      name: 'title',
      label: 'Name der Veranstaltung'
    },
    {
      type: 'date',
      name: 'date',
      label: 'Datum',
      isRequired: true,
      hasValidation: true
    },
    {
      type: 'time',
      name: 'start',
      label: 'Beginn'
    },
    {
      subFields: [
        {
          type: 'number',
          min: '1',
          name: 'fullDays',
          label: 'Dauer (ganze Tage)'
        },
        {
          type: 'number',
          min: '1',
          name: 'halfDays',
          label: 'Dauer (halbe Tage)'
        }
      ]
    },
    {
      type: 'number',
      min: '1',
      name: 'participants',
      label: 'Teilnehmerzahl',
      hasValidation: true
    }
  ],
  eventTypes: ['Workshop', 'Vortrag', 'Emptyspace']
};

export default formFields;
