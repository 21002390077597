import React, { useEffect, useState } from 'react';

import { Button } from '../global';

const cardClasses = 'bg-white rounded-lg shadow';
const dayButtons = [1, 2, 3, 4, 5];

const ContactForm = () => {
  const [halfDays, setHalfDays] = useState(null);
  const [fullDays, setFullDays] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [price, setPrice] = useState(null);

  const daySelectors = [
    {
      days: fullDays,
      fn: setFullDays,
      label: 'Ganze Tage'
    },
    {
      days: halfDays,
      fn: setHalfDays,
      label: 'Halbe Tage'
    }
  ];

  useEffect(() => {
    const calculatePrice = () => {
      const fixedRates = {
        halfTime: 600,
        fullTime: 1050,
        person: 25,
        cleaning: 220
      };

      const rent =
        halfDays * fixedRates.halfTime + fullDays * fixedRates.fullTime;
      const calculatedPrice = rent + participants * fixedRates.person + fixedRates.cleaning;
      const formattedPrice = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(calculatedPrice);

      setPrice(formattedPrice);
    };

    if ((halfDays || fullDays) && participants > 0) {
      calculatePrice();
    } else {
      setPrice(null);
    }
  }, [halfDays, fullDays, participants]);

  return (
    <div className="grid gap-12 max-w-[900px] mx-auto mt-24 md:gap-16 md:grid-cols-2">
      <div className="space-y-12">
        <div>
          <p className="mb-4">
            An wie vielen Tage soll Dein Event stattfinden?
          </p>
          <div className="flex space-x-8">
            {daySelectors.map((selector, index) => (
              <div key={index} className="text-center">
                <div className={`inline-block text-xl ${cardClasses}`}>
                  {dayButtons.map((nr, index) => (
                    <button
                      key={index}
                      className={`px-2.5 py-4 border-r-1 border-light-grey last:border-r-0 hover:bg-royal-blue-light ${
                        selector.days === nr ? 'bg-royal-blue-light' : ''
                      }`}
                      onClick={() => {
                        if (selector.days === nr) {
                          selector.fn(null);
                        } else {
                          selector.fn(nr);
                        }
                      }}
                    >
                      {nr}
                    </button>
                  ))}
                </div>
                <p className="text-base uppercase mt-3">{selector.label}</p>
              </div>
            ))}
          </div>
        </div>
        <label className="flex flex-col">
          Wie viele Teilnehmer hat Dein Event?
          <input
            type="number"
            className={`w-40 text-xl border-0 p-4 mt-4 ${cardClasses}`}
            placeholder="Teilnehmer"
            min="1"
            max="30"
            onChange={(e) => setParticipants(Number(e.target.value))}
          />
        </label>
      </div>
      <div>
        <div className={`text-center p-8 space-y-12 ${cardClasses}`}>
          <p className="text-h4">Dein unverbindlicher Preisvorschlag:</p>
          {price ? (
            <>
              <span className="block font-semibold text-4xl text-royal-blue">
                {price}*
              </span>
              <Button
                to="/kontakt"
                state={{ halfDays, fullDays, participants }}
                variant="royal-blue"
              >
                Jetzt anfragen
              </Button>
            </>
          ) : (
            <p className="text-royal-blue">
              Bitte wähle zur Berechnung die entsprechenden Daten aus.
            </p>
          )}
        </div>
        {price && (
          <p className="text-sm text-center mt-6">
            * plus Catering, wenn gewünscht
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
