import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Button } from '../global';

import formMessages from '../../content/form-messages';
import formFields from '../../content/form-fields';

const classes = {
  fieldset: 'space-y-8',
  label: 'flex flex-col',
  field: 'mt-1',
  error: 'text-info text-red mt-2.5'
};

const FormField = ({ field }) => (
  <div>
    <label htmlFor={field.name} className={classes.label}>
      {field.label}
      {field.isRequired && '*'}
      <Field
        id={field.name}
        type={field.type}
        name={field.name}
        min={field.min ? field.min : null}
        className={classes.field}
      />
    </label>
    {field.hasValidation && (
      <ErrorMessage
        name={field.name}
        render={(message) => <p className={classes.error}>{message}</p>}
      />
    )}
  </div>
);

const ContactForm = ({ state }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formValues = {
    company: '',
    forename: '',
    surname: '',
    email: '',
    phone: '',
    title: '',
    date: '',
    start: '',
    halfDays: state?.halfDays ? state.halfDays : '',
    fullDays: state?.fullDays ? state.fullDays : '',
    participants: state?.participants ? state.participants : '',
    eventType: '',
    message: '',
    dataPrivacy: false
  };

  const validateForm = (values) => {
    const errors = {};

    if (values.forename === '') {
      errors.forename = formMessages.noForename;
    }

    if (values.surname === '') {
      errors.surname = formMessages.noSurname;
    }

    if (values.email === '') {
      errors.email = formMessages.noMail;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = formMessages.invalidMail;
    }

    if (values.date === '') {
      errors.date = formMessages.noDate;
    }

    if (values.participants > 30) {
      errors.participants = formMessages.tooManyParticipants;
    }

    if (!values.dataPrivacy) {
      errors.dataPrivacy = formMessages.uncheckedDataPrivacy;
    }

    return errors;
  };

  const showErrorMessage = (actions) => {
    actions.setStatus({
      type: 'fail',
      message: formMessages.fail
    });
  };

  const sendForm = async (values, actions) => {
    setIsSubmitting(true);

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const body = JSON.stringify({
      company: values.company,
      forename: values.forename,
      surname: values.surname,
      email: values.email,
      phone: values.phone,
      title: values.title,
      date: values.date,
      start: values.start,
      halfDays: values.halfDays.toString(),
      fullDays: values.fullDays.toString(),
      participants: values.participants.toString(),
      eventType: values.eventType,
      message: values.message
    });

    const options = {
      method: 'POST',
      headers,
      body
    };

    const url =
      process.env.GATSBY_LOGICAPP_URL;

    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          actions.setStatus({
            type: 'success',
            message: formMessages.success
          });
        } else {
          showErrorMessage(actions);
        }
      })
      .catch(() => {
        showErrorMessage(actions);
      });
  };

  return (
    <div className="md:col-span-2">
      <p className="mb-8">
        Mit * gekennzeichnete Felder sind Pflichtfelder und müssen ausgefüllt
        werden.
      </p>
      <Formik
        initialValues={formValues}
        validate={(values) => validateForm(values)}
        onSubmit={(values, actions) => sendForm(values, actions)}
      >
        {({ status }) => (
          <Form className="space-y-14">
            <fieldset className={classes.fieldset}>
              <legend>Persönliche Informationen</legend>
              {formFields.personal.map((field, index) => (
                <FormField key={index} field={field} />
              ))}
            </fieldset>
            <fieldset className={classes.fieldset}>
              <legend>Veranstaltung</legend>
              {formFields.event.map((field, index) => {
                if (field.subFields) {
                  return (
                    <div key={index} className="grid grid-cols-2 gap-8">
                      {field.subFields.map((subField, subIndex) => (
                        <FormField key={subIndex} field={subField} />
                      ))}
                    </div>
                  );
                }

                return <FormField key={index} field={field} />;
              })}
              {/* Select */}
              <label htmlFor="eventType" className={classes.label}>
                Art des Events
                <Field
                  id="eventType"
                  as="select"
                  name="eventType"
                  className={classes.field}
                >
                  <option value="" disabled>
                    Auswählen
                  </option>
                  {formFields.eventTypes.map((eventType, index) => (
                    <option key={index} value={eventType}>
                      {eventType}
                    </option>
                  ))}
                </Field>
              </label>
            </fieldset>
            {/* Message */}
            <label htmlFor="message" className={classes.label}>
              Deine Nachricht
              <Field
                id="message"
                as="textarea"
                name="message"
                className="mt-4"
              />
            </label>
            {/* Checkbox (Data Privacy) */}
            <div>
              <label className="flex items-center">
                <Field type="checkbox" name="dataPrivacy" />
                <p>
                  Ich habe die Datenschutzhinweise gelesen und zur Kenntnis
                  genommen.{' '}
                  <a
                    href="https://codecamp-n.com/datenschutz/"
                    className="text-royal-blue hover:text-black"
                  >
                    Zum Datenschutzhinweis
                  </a>
                </p>
              </label>
              <ErrorMessage
                name="dataPrivacy"
                render={(message) => <p className={classes.error}>{message}</p>}
              />
            </div>
            {/* Submit */}
            {status === undefined && (
              <Button
                as="button"
                type="submit"
                disabled={isSubmitting}
                variant="royal-blue"
              >
                Jetzt senden
              </Button>
            )}
            {/* Success/Error Message */}
            {status && status.message && (
              <p
                className={`font-semibold text-xl ${status.type === 'success' ? '' : 'text-red'
                  }`}
              >
                {status.message}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

ContactForm.propTypes = {
  state: PropTypes.shape({
    days: PropTypes.number,
    participants: PropTypes.number
  })
};

export default ContactForm;
